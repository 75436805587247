import L from "leaflet";
import '~/lib/leaflet.layer.soviet-topomaps-grid';
import {DodiesLvLayer} from '~/lib/leaflet.layer.dodieslv';
import {VesturesStastiLayer} from '~/lib/leaflet.layer.vesture';
import {RobezpunktiLayer} from '~/lib/leaflet.layer.robezpunkti';
import {LatviaTopoGrid} from '~/lib/leaflet.layer.latvia-topo-grid';

//  codes
// 0 = '1752-1835 Atlas von Liefland'
// 1 = '1839 Specialcharte von Livland'
// 2 = '1866 Военно-топографическая (3 verst)'
// 3 = '1915 Военно-топографическая (3 verst)',
// 4 = '1915 Военно-топографическая (3 verst vac)',
// 5 = '1915 Военно-топографическая (2 verst)',
// 6 = '1927 Военно-топографическая (2verst R)',
// 7 = 1 verst
// 8 = '17. gs. Zviedru muižas'
// 9 = '17. gs. Rīgas Patrimonālais apgabals'
// A = '1917 Vācu armija 1:10K'
// B = CORONA
// C = Cēsis
// D = DVM
// E = ESRI world
// F = LVM Ortofoto
// G = LGIA Ortofoto 1994
// H = Riga 1883
// I = RKKA 100K
// J = PGM
// K = Fliegerkarte
// L = 1917 Karte von Kurland 50K
// M = Mantojums
// N = NARA
// O = OSM
// P = Pārgājieni
// R = Rail
// S = Plansetes Kadastra
// T = heereskarte
// U = autocelu
// V = Velosipēdu
// W = LGIA Topo
// X = '1820 Geograficheskij atlas Rossijskoj Imperii'
// Y = '1905 Specialnaja Karta Evropejskoj Rossii'
// Z = 1885 Stadplan von Riga 1:12600

    const layersDefs = [
                {
                    title: 'Jāņa Sētas rastra karte',
                    isDefault: true,
                    layer: L.tileLayer.wms('https://wms.kartes.lv/VDRW/wgs/15/?',
                        {isOverlay: false, code: 'J', scaleDependent: true, attribution: '&copy; <a href="https://www.kartes.lv">Jāņa sēta</a>'}
                    )
                },
                {
                    title: 'OpenStreetMap',
                    description: 'OSM <a href="http://www.openstreetmap.org">(c)</a>',
                    isDefault: true,
                    layer: L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                        {isOverlay: false,
                        maxZoom: 22,
                        maxNativeZoom: 19,
                        code: 'O',
                        scaleDependent: true,
                        attribution: '&copy; <a href="https://www.openstreetmap.org">OpenStreetMap</a> contributors'}
                        )
                },
                {
                    title: 'OSM Outdoors',
                    description: 'OSM <a href="http://www.openstreetmap.org">(c)</a>',
                    isDefault: true,
                    layer: L.tileLayer('//tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=ad1b3c76436d450ca99ba3eecb692a3f',
                        {isOverlay: false,
                        maxZoom: 22,
                        maxNativeZoom: 19,
                        code: 'TH',
                        scaleDependent: true,
                        attribution: '&copy; <a href="https://www.openstreetmap.org">OpenStreetMap</a> contributors'}
                        )
                },
/*                {
                    title: 'HERE',
                    description: 'Here <a href="http://www.here.com">(c)</a>',
                    isDefault: true,
                    layer: L.tileLayer('https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=hL-ExrDWxrQeWHvat_GHQt514ubo2nVOpzLh0xGdDBY&ppi=320',
                        {isOverlay: false, maxZoom: 22, maxNativeZoom: 19, code: 'O', scaleDependent: true, print: true, jnx: true}
                    )
                },
*/
                {
                    title: 'Google Terrain',
                    isDefault: false,
                    layer: new L.Layer.GoogleTerrain({
                            code: 'GT',
                            isOverlay: false,
                            scaleDependent: false,
                            print: true,
                            jnx: true,
                            shortName: 'google_terrain',
                            attribution: '&copy; <a href="https://www.google.com/maps/">Google maps</a>',
                        }
                    )
                },
               {
                    title: 'LVM DVM',
                    description: '<a href="http://www.lvmgeo.lv/dati">LVM</a>',
                    isDefault: true,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {isOverlay: true, code: 'D', scaleDependent: true, print: true, jnx: false, layers: 'public:ZemeLKS', attribution: '&copy; <a href="https://lvmgeo.lv">Latvjas Valsts Meži</a>'}
                    )
                },
               {
                    title: 'LVM Slīpums',
                    description: '<a href="http://www.lvmgeo.lv/dati">LVM</a>',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {isOverlay: true, code: 'SLI', scaleDependent: true, print: true, jnx: false, layers: 'public:SlopeLKS'}
                    )
                },
               {
                    title: 'LVM Vainagi',
                    description: '<a href="http://www.lvmgeo.lv/dati">LVM</a>',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {isOverlay: true, code: 'VAI', scaleDependent: true, print: true, jnx: false, layers: 'public:CanopyHeightLKS'}
                    )
                },
                {
                    title: 'LVM Ortofoto',
                    description: '<a href="http://www.lvmgeo.lv/dati">LVM</a>',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {isOverlay: false, maxZoom: 22, maxNativeZoom: 19, code: 'F', scaleDependent: true, print: true, jnx: false, layers: 'public:Orto_LKS'}
                    )
                },
               {
                    title: '2020 Latvijas Republikas pārskata karte 1:250k',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/2020_LR_parskata_karte_250k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'PAR', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
               {
                    title: '1921 Latvijas karte',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1921_latvijas_karte/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'LAT', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
               {
                    title: '1928 Latvijas satiksmes ceļu Karte 1:400k',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1928_latvijas_satiksmes_celu_karte_400k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'SAT', scaleDependent: false, maxNativeZoom: 11, print: true, jnx: true}
                    )
                },
               {
                    title: '1931 Latvijas ceļu un pagastu robežu karte 1:300k',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1931_latvijas_celu_un_pagastu_robezu_karte_300k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'LVC', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
               {
                    title: '1935 Latvijas autoceļi 1:500k',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1935_latvijas_autoceli_500k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'AUC', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
               {
                    title: '1920-1940 Latvijas Armijas 1:50k',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/LVARM_40_50k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'ARM', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
               {
                    title: '1933 P. Mantnieka 1:250k',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1933_P_Mantnieka_250k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'MA2', scaleDependent: false, maxNativeZoom: 11, print: true, jnx: true}
                    )
                },
               {
                    title: '1950 P. Mantnieka 1:450k',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1950_P_Mantnieka_450k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'MA4', scaleDependent: false, maxNativeZoom: 11, print: true, jnx: true}
                    )
                },
                {
                    title: 'Kadastrs OpenData',
                    isDefault: true,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {
                            scaleDependent: true,
                            print: true,
                            layers: "publicwfs:Kadastra_karte",
                            format: "image/png",
                            transparent: true,
                            code: 'kd',
                            isOverlay: true,
                            jnx: true,
                            attribution: "Kadastra informācijas sistēmas atvērtie telpiskie dati. <a href=\"https://creativecommons.org/licenses/by/4.0/deed.lv\">CC BY 4.0</a>",
                            maxZoom: 20
                      })
                },
                {
                    title: 'ESRI world imagery',
                    description: 'ESRI <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer">(c)</a>',
                    isDefault: true,
                    layer: L.tileLayer('//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                        {isOverlay: false, code: 'E', scaleDependent: true, print: true, jnx: true}
                    )
                },
                {
                    title: 'Soviet topo maps grid',
                    isDefault: false,
                    layer: new L.Layer.SovietTopoGrid({
                        code: 'GRD',
                        isOverlay: true,
                        print: false,
                        noOpacity: true,
                        jnx: false
                    })
                },
               {
                    title: '17. gs. Zviedru muižas',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/17gsmuizas/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '8', scaleDependent: false, maxNativeZoom: 16, print: true, jnx: true}
                    )
                },
               {
                    title: '18. gs Планы Генерального межевания',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/pgm/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'PGM', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
               {
                    title: '19. gs. Popes muiža',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/popes_muiza/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'POP', scaleDependent: false, maxNativeZoom: 16, print: true, jnx: true}
                    )
                },
               {
                    title: '17. gs. Rīgas Patrimonālais apgabals',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/17gspatriga/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '9', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
               {
                    title: '1752-1835 Atlas von Liefland',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1754_atlas_von_liefland/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '0', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
               {
                    title: '1839 Specialcharte von Livland',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1839_specialarte_von_livland_186k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '1', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
               {
                    title: '1839 Specialcharte von Livland papildinata',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/spezialcharte2/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'SP2', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
               {
                    title: '1820 Geograficheskij atlas Rossijskoj Imperii',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1820_geograficheskoi/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'X', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
               {
                    title: '1866 Военно-топографическая (3 verst)',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-3verst1/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '2', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
               {
                    title: '1884 Special-Karte von Mittel-Europa 1:200k',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1884_specialkarte_von_mitteleuropa_200k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'ME', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                    title: '1915 Военно-топографическая (3 verst)',
                    isDefault: false,
                    layer: L.tileLayer(
//                      'https://home.dodies.lv/tiles-3verst2/z{z}/{y}/{x}.png',
                        'https://home.dodies.lv/1915_ru_126k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '3', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
                {
                    title: '1915 Военно-топографическая (Vāc.)',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-3verst2-german/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '4', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                    title: '1905 Specialnaja Karta Evropejskoj Rossii',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1905_specialnaja_karta_e/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'Y', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                    title: '1915 Военно-топографическая (2verst O)',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-2verst-o/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '5', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1917 Zusammendruck 1:100k',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1917_zusammendruck_100k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'ZD', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1918 Ubersichtskarte der 8. armee 1:100k',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1918_ubersichtskarte_der_8_armee_100k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '8A', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1940 Grosßblatt 1:100k',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1940_grosblatt_100k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'GB', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1927 Военно-топографическая (2verst R)',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-2verst-r/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '6', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1908 Военно-топографическая (1 verst)',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-1verst/{z}/{x}/{y}.png',
                        {isOverlay: true, code: '7', scaleDependent: false, maxNativeZoom: 15, print: true, jnx: true}
                    )
                },
                {
                    title: '1905 РККА 100K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1905_rkka_100k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'I', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
                {
                    title: '1940 РККА 200K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1940_rkka_200k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'R20', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
                {
                title: '1914 Übersichtskarte von Mitteleuropa',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-1914-300k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'MIT', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                title: '1915 Operationskarte 1:800K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1915_operationskarte_800k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'OP', scaleDependent: false, maxNativeZoom: 11, print: true, jnx: true}
                    )
                },
                {
                title: '1917 Vācu armija 1:10K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1917_vacu_armija_10k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'A', scaleDependent: false, maxNativeZoom: 16, print: true, jnx: true}
                    )
                },
                {
                title: '1917 Vācu armija 1:25K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1917_vacu_armija_25k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'VA', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                title: '1916 Fliegerkarte',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1916_fliegerkarte_200k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'K', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                title: '1917 Karte von Kurland 50K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1917_karte_von_kurland_50k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'L', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                title: '1924 WIG 100K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1924_wig_100k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'WI', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                title: '1934 Latvijas Ceļu Karte 300K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/autoceli_1934_300k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'AC', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                    title: '1914-19 Karte des westlichen Russlands',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-kdwr/z{z}/{y}/{x}.png',
                        {isOverlay: true, code: 'KDW', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1914-19 West. Osteuropa 25K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-kdwr-25k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'OST', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1920-1940 Kadastrs',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-kadastr/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'S', scaleDependent: false, maxNativeZoom: 17, print: true, jnx: true}
                    )
                },
                {
                    title: '1920-1940 Latvijas armijas 200K ',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-latarm-200k/{z}/{x}/{y}.png',
                        {isOverlay: true,
                        code: 'LA200',
                        zIndex: 1,
                        scaleDependent: false,
                        maxNativeZoom: 13,
                        print: true,
                        jnx: true,
                        minZoom: 8,
                        bounds: [[57.98481, 20.95642], [55.48508, 28.52051]]}
                    )
                },
                {
                    title: '1920-1940 Latvijas armijas 75K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-75/{z}/{x}/{y}.png',
                        {isOverlay: true,
                        code: 'LA75',
                        scaleDependent: false,
                        maxNativeZoom: 13,
                        print: true,
                        jnx: true,
                        minZoom: 8,
                        bounds: [[58.32680, 20.98389], [55.58766, 28.54248]]}
                    )
                },
                {
                    title: '1928-1936 Latvijas armijas 25K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/LVARM_40_25k_v2/{z}/{x}/{y}.png',
//                      'https://home.dodies.lv/tiles-latarm/z{z}/{y}/{x}.png',
                        {isOverlay: true,
                        code: 'LA25',
                        scaleDependent: false,
                        maxNativeZoom: 15,
                        print: true,
                        jnx: true,
                        minZoom: 8,
                        bounds: [[57.00186, 25.49583], [55.66055, 27.88055]]}
                    )
                },
                {
                    title: '1931 РККА 50K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1940_rkka_50k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'RKK', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1933 Jaunlatgales apriņķa ceļu karte 1:50K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1933_jaunlatgales_celu_karte_50k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'JA', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1936 Latvijas Polijas robeža',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/polijas-robeza/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'PL', scaleDependent: false, maxNativeZoom: 17, print: true, jnx: true}
                    )
                },
                {
                    title: '1940 Karte von Luftwaffe ',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-osteuropa/z{z}/{y}/{x}.png',
                        {isOverlay: true,
                         code: 'LUF',
                         scaleDependent: false,
                         maxNativeZoom: 14,
                         minZoom: 9,
                         bounds: [[58.14245, 24.33746], [55.65270, 28.33117]],
                         print: true,
                         jnx: true}
                    )
                },
                {
                    title: '1940 Latvijas ceļu karte',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-autocelu/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'U', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                    title: '1940 Estland-Lettland 100K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1940_estland_lettland_100k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'ES', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
                {
                    title: '1941 Strassenkarte der Baltischen Länder 300K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1941_Strassenkarte_der_Baltischen_300k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'ST', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                    title: '1942 Deutsche Heereskarte 100K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/1942_heereskarte_100k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'T', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
                {
                    title: '1943 Deutsche Heereskarte 300K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1943_heereskarte_300k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'TE', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
                {
                    title: '1940 РККА 500K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-rkka-500k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'RK5', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1954 US ARMY',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-usarmy54-200k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'US', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                    title: '1940 NARA Aerofoto',
                    isDefault: false,
                    layer: L.tileLayer('https://tiles.anrijs.lv/aerofoto_1940/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'N', scaleDependent: false, maxNativeZoom: 17, print: true, jnx: true}
                    )
                },
                {
                    title: '1967-1972 CORONA Aerofoto',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-corona/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'B', scaleDependent: false, maxNativeZoom: 15, print: true, jnx: true}
                    )
                },
               {
                    title: '1947 Генеральный штаб 25K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-47-25k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'GE0', scaleDependent: false, maxNativeZoom: 15, print: true, jnx: true}
                    )
                },
                {
                    title: '1963 Генеральный штаб 25K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-63-25k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'GE1', scaleDependent: false, maxNativeZoom: 15, print: true, jnx: true}
                    )
                },
                {
                    title: '1986 Генеральный штаб 25K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-86-25k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'GE2', scaleDependent: false, maxNativeZoom: 15, print: true, jnx: true}
                    )
                },
                {
                    title: '1986 Генеральный штаб 50K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-genshtab/z{z}/{y}/{x}.png',
                        {isOverlay: true, code: 'GE3', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                    title: '1986 Генеральный штаб 1:1M',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/PSRS_1M/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'G1', scaleDependent: false, maxNativeZoom: 10, print: true, jnx: true}
                    )
                },
                {
                    title: '1986 Генеральный штаб 1:500K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/PSRS_500k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'G500', scaleDependent: false, maxNativeZoom: 11, print: true, jnx: true}
                    )
                },
                {
                    title: '1986 Генеральный штаб 1:100K',
                    isDefault: true,
                    layer: L.tileLayer('https://home.dodies.lv/PSRS_100k/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'G100', scaleDependent: false, maxNativeZoom: 13, print: true, jnx: true}
                    )
                },
               {
                    title: 'Генеральный штаб C-system 10K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-10C/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'GEC', scaleDependent: false, maxNativeZoom: 16, print: true, jnx: true}
                    )
                },
                {
                    title: 'Генеральный штаб Pilsētas 10K',
                    isOverlay: true,
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-10P/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'GEP', scaleDependent: false, maxNativeZoom: 16, print: true, jnx: true}
                    )
                },
                {
                    title: 'Генеральный штаб O-system 10K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/tiles-10O/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'GEO', scaleDependent: false, maxNativeZoom: 16, print: true, jnx: true}
                    )
                },
                {
                title: '1883 Karte der stadt Riga 1:4200',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/riga-1883/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'H', scaleDependent: false, maxNativeZoom: 23, print: true, jnx: true}
                    )
                },
                {
                title: '1867 Gouvernements-Stadt Riga. Wieckmann',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/riga-1867/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'Wieck', tms: false, scaleDependent: false, maxNativeZoom: 22, print: true, jnx: true}
                    )
                },
                {
                title: '1885 Stadplan von Riga 1:12600',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/riga-1885/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'Z', scaleDependent: false, maxNativeZoom: 17, print: true, jnx: true}
                    )
                },
                {
                title: '1936 Ernst Plate Rīga',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/riga-1936-ernstplates-maz/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'RIC', scaleDependent: false, maxNativeZoom: 17, print: true, jnx: true}
                    )
                },
                {
                title: '1937 Rīgas plāns',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/riga-1937/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'RIB', scaleDependent: false, maxNativeZoom: 15, print: true, jnx: true}
                    )
                },
                {
                title: '1993 Rīgas plāns',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/riga-198x/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'RIA', scaleDependent: false, maxNativeZoom: 17, print: true, jnx: true}
                    )
                },
                {
                title: '1926 Cēsu plāns',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/cesis-1926/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'C', scaleDependent: false, maxNativeZoom: 18, print: true, jnx: true}
                    )
                },
                {
                title: '1798 Valmieras plāns',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1798_Valmiera/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'VA', scaleDependent: false, maxNativeZoom: 18, print: true, jnx: true}
                    )
                },
                {
                title: '1990 Virsmežniecību shēmas',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/vvm_shemas_1990/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'SH', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                title: '2013 LR pārskata karte 1:250K',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/2013_LR_parskata_karte_250k/{z}/{x}/{y}.png',
                        {isOverlay: false, code: 'LR', scaleDependent: false, maxNativeZoom: 14, print: true, jnx: true}
                    )
                },
                {
                title: '1938 Telefonu vadu tīkla shēma',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/1938_telefonu_vadu_shema/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'TEL', scaleDependent: false, maxNativeZoom: 12, print: true, jnx: true}
                    )
                },
                {
                title: '1918-1919 Brīvības cīņas',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/brivibas/1918-1919/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'BR1', scaleDependent: false, maxNativeZoom: 10, print: true, jnx: true}
                    )
                },
                {
                title: '1919-1920 Brīvības cīņas',
                    isDefault: false,
                    layer: L.tileLayer('https://home.dodies.lv/brivibas/1919-1920/{z}/{x}/{y}.png',
                        {isOverlay: true, code: 'BR2', scaleDependent: false, maxNativeZoom: 10, print: true, jnx: true}
                    )
                },
                {
                    title: 'TKS-93 karšu nomenklatūras lapas',
                    isDefault: true,
                    layer: new LatviaTopoGrid({
                        code: 'tks',
                        isOverlay: true,
                        print: false,
                        noOpacity: true,
                        jnx: false
                  })
                },
                {
                    title: 'OpenRailway map dzelzceļi',
                    isDefault: false,
                    layer: L.tileLayer(
                    'https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png',
                    {isOverlay: true, code: 'R', zIndex: 10, scaleDependent: true, maxNativeZoom: 19, print: true, jnx: true}
                    )
                },
                {
                    title: '1994-1999 LĢIA ortofoto',
                    description: '<a href="https://map.lgia.gov.lv/index.php?lang=0&cPath=4_49&txt_id=117">LGIA</a>',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://services.lgia.gov.lv/arcfree/services/WMS_Ortofoto_v1/MapServer/WmsServer?',
                    {isOverlay: true,
                        code: 'G',
                        scaleDependent: true,
                        print: true,
                        jnx: false,
                        layers: '0'}
                    )
                },
               {
                    title: 'LĢIA Topo',
                    description: '<a href="http://www.lvmgeo.lv/dati">LVM</a>',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {isOverlay: true, code: 'W', scaleDependent: true, print: true, jnx: false, layers: 'public:Topo50'}
                    )
                },
               {
                    title: 'LĢIA Topo reljefs',
                    description: '<a href="http://www.lvmgeo.lv/dati">LVM</a>',
                    isDefault: true,
                    layer: L.tileLayer.wms('https://lvmgeoserver.lvm.lv/geoserver/ows?',
                        {isOverlay: false, code: 'RL', scaleDependent: true, print: true, jnx: false, layers: 'public:Topo10DTM'}
                    )
                },
                {
                    title: 'Kultūras mantojums',
                    description: '<a href="http://is.mantojums.lv">Mantojums</a>',
                    isDefault: false,
                    layer: L.tileLayer.wms('https://geoserver.mantojums.lv/geoserver/monument/wms?',
                        {isOverlay: true,
                        code: 'M',
                        scaleDependent: true,
                        print: true,
                        jnx: false,
                        version: '1.1.1',
                        format: 'image/png',
                        transparent: true,
                        layers: 'monument:monument:monuments_culturalobject_building_all,monument:monument:monuments_culturalobject_construction_all,monument:monuments_culturalobject_archeology_all,monument:monuments_culturalobject_archeology_actual'}

//                        layers: 'monument:monuments_culturalobject_archeology_actual'}
//                        layers: 'Arheoloģija,Pieminekļu pārskats,Būvas/ēkas1,Arhitektūra (pilsētbūvniecība),Apbūve neaktuāla,Apbūve,Vēsturiska notikuma vieta,Vēsture,Industriālais'}
                    )
                },
                {
                    title: 'Dodies.lv Takas',
                    isDefault: true,
                    layer: new DodiesLvLayer("https://dodies.lv/",
                        {
                            geojsonUrl: 'json/lvtaka.geojson',
                            aprakstsBaseUrl: 'https://dodies.lv/',
                            routesUrl: 'json/routes/',
                            code: 'lvtaka',
                            isOverlay: true,
                            noOpacity: true,
                            scaleDependent: false,
                            shortName: 'lvtaka',
                      })
                },
                {
                title: 'Dodies.lv Torņi',
                    isDefault: true,
                    layer: new DodiesLvLayer("https://dodies.lv/",
                        {
                            geojsonUrl: 'json/lvtornis.geojson',
                            aprakstsBaseUrl: 'https://dodies.lv/',
                            code: 'lvtornis',
                            isOverlay: true,
                            noOpacity: true,
                            scaleDependent: false,
                            shortName: 'lvtornis',
                      })
                },
                {
                title: 'Dodies.lv Pikniki',
                    isDefault: true,
                    layer: new DodiesLvLayer("https://dodies.lv/",
                        {
                            geojsonUrl: 'json/lvpikniks.geojson',
                            aprakstsBaseUrl: 'https://dodies.lv/',
                            code: 'lvpikniks',
                            isOverlay: true,
                            noOpacity: true,
                            scaleDependent: false,
                            shortName: 'lvpikniks',
                      })
                },
                {
                    title: 'Pārgājienu maršruti',
                    isDefault: false,
                    layer: L.tileLayer(
                    'https://tile.waymarkedtrails.org/hiking/{z}/{x}/{y}.png',
                    {isOverlay: true, detectRetina: true, code: 'P', zIndex: 10, scaleDependent: true, maxNativeZoom: 19, print: true, jnx: true}
                    )
                },
                {
                    title: 'Velosipēdu maršruti',
                    isDefault: false,
                    layer: L.tileLayer(
                    'https://tile.waymarkedtrails.org/cycling/{z}/{x}/{y}.png',
                    {isOverlay: true, detectRetina: true, code: 'V', zIndex: 10, scaleDependent: true, maxNativeZoom: 19, print: true, jnx: true}
                    )
                },
                {
                    title: 'MTB maršruti',
                    isDefault: false,
                    layer: L.tileLayer(
                    'https://tile.waymarkedtrails.org/mtb/{z}/{x}/{y}.png',
                    {isOverlay: true, detectRetina: true, code: 'MTB', zIndex: 10, scaleDependent: true, maxNativeZoom: 19, print: true, jnx: true}
                    )
                },
                {
                    title: '1928 Robežpunkti LV-EE 1:25k',
                    isDefault: false,
                    layer: new RobezpunktiLayer(
                        'https://home.dodies.lv/1928_lv_ee_robezpunkti/',
                        {
                            code: 'robeza',
                            isOverlay: true,
                            noOpacity: true,
                            tms: false,
                            print: true,
                            jnx: true,
                            scaleDependent: false,
                            shortName: 'robeza_',
                            isOverlayTransparent: false,
                            maxNativeZoom: 15,
                            minZoom: 0,
                            notes: ''
                        }
                    )
                },
                {
                    title: 'Ziemeļvidzemes noklusētie stāsti',
                    isDefault: false,
                    layer: new VesturesStastiLayer(
                        'https://home.dodies.lv/vmd-bildes/', // <-- pilns path uz publisko mapi
                        {
                        code: 'Ge',
                        isOverlay: true,
                           tms: false,
                           print: true,
                           jnx: true,
                           scaleDependent: false,
                           shortName: 'geo_',
                           isOverlayTransparent: false,
                           maxNativeZoom: 18,
                           noOpacity: true,
                           minZoom: 0,
                        }
                    )
                }
            ];

    const groupsDefs = [
        {
            title: 'Pārskata kartes',
            collapse: true,
            layers: [
            '1820 Geograficheskij atlas Rossijskoj Imperii',
            '1905 Specialnaja Karta Evropejskoj Rossii',
            '1915 Operationskarte 1:800K',
            '1920-1940 Latvijas armijas 200K ',
            '1928 Latvijas satiksmes ceļu Karte 1:400k',
            '1928 Latvijas satiksmes ceļu Karte 1:400k',
            '1934 Latvijas Ceļu Karte 300K',
            '2020 Latvijas Republikas pārskata karte 1:250k',
            '1931 Latvijas ceļu un pagastu robežu karte 1:300k',
            '1933 P. Mantnieka 1:250k',
            '1935 Latvijas autoceļi 1:500k',
            '1950 P. Mantnieka 1:450k',
            '1986 Генеральный штаб 1:1M'
            ],
        },
        {
            title: 'Detalizētās kartes',
            collapse: true,
            layers: [
           '17. gs. Zviedru muižas',
           '1752-1835 Atlas von Liefland',
           '18. gs Планы Генерального межевания',
           '1839 Specialcharte von Livland papildinata',
           '1839 Specialcharte von Livland',
           '1866 Военно-топографическая (3 verst)',
           '1905 РККА 100K',
           '1908 Военно-топографическая (1 verst)',
           '1914 Übersichtskarte von Mitteleuropa',
           '1914-19 Karte des westlichen Russlands',
           '1914-19 West. Osteuropa 25K',
           '1915 Военно-топографическая (2verst O)',
           '1915 Военно-топографическая (3 verst)',
           '1915 Военно-топографическая (Vāc.)',
           '1916 Fliegerkarte',
           '1917 Karte von Kurland 50K',
           '1917 Vācu armija 1:10K',
           '1917 Vācu armija 1:25K',
           '1918 Ubersichtskarte der 8. armee 1:100k',
           '1940 Grosßblatt 1:100k',
           '1917 Zusammendruck 1:100k',
           '1884 Special-Karte von Mittel-Europa 1:200k',
           '1920-1940 Latvijas armijas 75K',
           '1920-1940 Latvijas Armijas 1:50k',
           '1921 Latvijas karte',
           '1924 WIG 100K',
           '1927 Военно-топографическая (2verst R)',
           '1928-1936 Latvijas armijas 25K',
           '1931 РККА 50K',
           '1940 Estland-Lettland 100K',
           '1940 Karte von Luftwaffe ',
           '1940 Latvijas ceļu karte',
           '1940 РККА 200K',
           '1940 РККА 500K',
           '1941 Strassenkarte der Baltischen Länder 300K',
           '1942 Deutsche Heereskarte 100K',
           '1943 Deutsche Heereskarte 300K',
           '1947 Генеральный штаб 25K',
           '1954 US ARMY',
           '1963 Генеральный штаб 25K',
           '1986 Генеральный штаб 1:500K',
           '1986 Генеральный штаб 1:100K',
           '1986 Генеральный штаб 25K',
           '1986 Генеральный штаб 50K',
           'Генеральный штаб C-system 10K',
           'Генеральный штаб O-system 10K',
           'Генеральный штаб Pilsētas 10K'
            ],
        },
        {
            title: 'Pilsētas',
            layers: [
                    '17. gs. Rīgas Patrimonālais apgabals',
                    '19. gs. Popes muiža',
                    '1798 Valmieras plāns',
                    '1867 Gouvernements-Stadt Riga. Wieckmann',
                    '1883 Karte der stadt Riga 1:4200',
                    '1885 Stadplan von Riga 1:12600',
                    '1933 Jaunlatgales apriņķa ceļu karte 1:50K',
                    '1936 Ernst Plate Rīga',
                    '1937 Rīgas plāns',
                    '1993 Rīgas plāns',
                    '1926 Cēsu plāns'
                    ],
        },
        {
            title: 'Aerofoto',
            collapse: true,
            layers: [
                    'LVM DVM',
                    'LVM Slīpums',
                    'LVM Vainagi',
                    '1994-1999 LĢIA ortofoto',
                    '1967-1972 CORONA Aerofoto',
                    '1940 NARA Aerofoto'
            ],
        },
        {
            title: 'Shematiski materiāli',
            layers: [
                    'Soviet topo maps grid',
                    'OpenRailway map dzelzceļi',
                    '1928 Robežpunkti LV-EE 1:25k',
                    '1936 Latvijas Polijas robeža',
                    '1990 Virsmežniecību shēmas',
                    '1920-1940 Kadastrs',
                    '1938 Telefonu vadu tīkla shēma',
                    'Kultūras mantojums',
                    'Dodies.lv Takas',
                    'Dodies.lv Torņi',
                    'Dodies.lv Pikniki',
                    'Velosipēdu maršruti',
                    'MTB maršruti',
                    'TKS-93 karšu nomenklatūras lapas',
                    'Pārgājienu maršruti',
                    'Ziemeļvidzemes noklusētie stāsti',
                    'Kadastrs OpenData',
                    '1918-1919 Brīvības cīņas',
                    '1919-1920 Brīvības cīņas'
                    ],
        },
        {
            title: 'Pamatnes',
            layers: [
                    'Jāņa Sētas rastra karte',
                    'Google Terrain',
                    'LVM Ortofoto',
                    'OpenStreetMap',
                    'OSM Outdoors',
                    'ESRI world imagery',
                    'LĢIA Topo',
                    'LĢIA Topo reljefs',
                    '2013 LR pārskata karte 1:250K'
            ],
        }
    ];

    const titlesByOrder = [
                    'Jāņa Sētas rastra karte',
                    'LĢIA Topo',
                    'LĢIA Topo reljefs',
                    'Google Terrain',
                    'OpenStreetMap',
                    'OSM Outdoors',
                    'LVM Ortofoto',
                    'ESRI world imagery',
                    '2013 LR pārskata karte 1:250K',
                    'SENTINEL',
                    'LVM DVM',
                    'LVM Slīpums',
                    'LVM Vainagi',
                    '#custom-bottom',
                    '2020 Latvijas Republikas pārskata karte 1:250k',
                    '1928 Latvijas satiksmes ceļu Karte 1:400k',
                    '1931 Latvijas ceļu un pagastu robežu karte 1:300k',
                    '1921 Latvijas karte',
                    '1933 P. Mantnieka 1:250k',
                    '1935 Latvijas autoceļi 1:500k',
                    '1950 P. Mantnieka 1:450k',
                    '1986 Генеральный штаб 1:1M',
                    '1915 Operationskarte 1:800K',
                    '1986 Генеральный штаб 1:500K',
                    '1752-1835 Atlas von Liefland',
                    '1820 Geograficheskij atlas Rossijskoj Imperii',
                    '1905 Specialnaja Karta Evropejskoj Rossii',
                    '1940 РККА 500K',
                    '1940 РККА 200K',
                    '1920-1940 Latvijas armijas 200K ',
                    '1954 US ARMY',
                    '1941 Strassenkarte der Baltischen Länder 300K',
                    '1943 Deutsche Heereskarte 300K',
                    '1934 Latvijas Ceļu Karte 300K',
                    '1986 Генеральный штаб 1:100K',
                    '1918 Ubersichtskarte der 8. armee 1:100k',
                    '1940 Grosßblatt 1:100k',
                    '1917 Zusammendruck 1:100k',
                    '1884 Special-Karte von Mittel-Europa 1:200k',
                    '1994-1999 LĢIA ortofoto',
                    '1967-1972 CORONA Aerofoto',
                    '1940 NARA Aerofoto',
                    '1839 Specialcharte von Livland',
                    '1839 Specialcharte von Livland papildinata',
                    '1866 Военно-топографическая (3 verst)',
                    '1905 РККА 100K',
                    '1915 Военно-топографическая (3 verst)',
                    '1915 Военно-топографическая (Vāc.)',
                    '1916 Fliegerkarte',
                    '1924 WIG 100K',
                    '1917 Karte von Kurland 50K',
                    '1915 Военно-топографическая (2verst O)',
                    '1927 Военно-топографическая (2verst R)',
                    '1908 Военно-топографическая (1 verst)',
                    '1914 Übersichtskarte von Mitteleuropa',
                    '1914-19 Karte des westlichen Russlands',
                    '1914-19 West. Osteuropa 25K',
                    '1920-1940 Latvijas armijas 75K',
                    '1928-1936 Latvijas armijas 25K',
                    '1920-1940 Latvijas Armijas 1:50k',
                    '1931 РККА 50K',
                    '1940 Latvijas ceļu karte',
                    '1940 Karte von Luftwaffe ',
                    '1940 Estland-Lettland 100K',
                    '1942 Deutsche Heereskarte 100K',
                    '1933 Jaunlatgales apriņķa ceļu karte 1:50K',
                    '1917 Vācu armija 1:25K',
                    '1917 Vācu armija 1:10K',
                    '1986 Генеральный штаб 50K',
                    '1947 Генеральный штаб 25K',
                    '1963 Генеральный штаб 25K',
                    '1986 Генеральный штаб 25K',
                    'Генеральный штаб C-system 10K',
                    'Генеральный штаб Pilsētas 10K',
                    'Генеральный штаб O-system 10K',
                    '1928 Robežpunkti LV-EE 1:25k',
                    '1936 Latvijas Polijas robeža',
                    '1920-1940 Kadastrs',
                    '18. gs Планы Генерального межевания',
                    '17. gs. Rīgas Patrimonālais apgabals',
                    '17. gs. Zviedru muižas',
                    '19. gs. Popes muiža',
                    '1883 Karte der stadt Riga 1:4200',
                    '1867 Gouvernements-Stadt Riga. Wieckmann',
                    '1885 Stadplan von Riga 1:12600',
                    '1936 Ernst Plate Rīga',
                    '1937 Rīgas plāns',
                    '1993 Rīgas plāns',
                    '1798 Valmieras plāns',
                    '1926 Cēsu plāns',
                    '1990 Virsmežniecību shēmas',
                    '1938 Telefonu vadu tīkla shēma',
                    'Kadastrs OpenData',
                    'Soviet topo maps grid',
                    'OpenRailway map dzelzceļi',
                    'Kultūras mantojums',
                    'Velosipēdu maršruti',
                    'Pārgājienu maršruti',
                    'MTB maršruti',
                    'Dodies.lv Pikniki',
                    'Dodies.lv Torņi',
                    'Dodies.lv Takas',
                    'Ziemeļvidzemes noklusētie stāsti',
                    'Kadastrs OpenData',
                    'TKS-93 karšu nomenklatūras lapas',
                    '1918-1919 Brīvības cīņas',
                    '1919-1920 Brīvības cīņas',
                    '#custom-top'
    ];

function getLayers(tracklist) {
    // set metadata
    for (let layer of layersDefs) {
        layer.layer.meta = {title: layer.title};
        layer.layer.tracklist = tracklist;
    }

    // assign order to layers
    const orderByTitle = {};
    for (let i = 0; i < titlesByOrder.length; i++) {
        let title = titlesByOrder[i];
        orderByTitle[title] = i + 1;
    }

    for (let layer of layersDefs) {
        const title = layer.title;
        layer.order = orderByTitle[title];
        if (!layer.order) {
            throw new Error(`Layer title not found in titlesByOrder list: ${title}`);
        }
    }

    // divide layers by groups
    const grouppedLayers = [];
    const layersByTitle = {};
    for (let layer of layersDefs) {
        layersByTitle[layer.title] = layer;
    }
    for (let groupDef of groupsDefs) {
        let collapse = groupDef.collapse === true;
        let group = {group: groupDef.title, collapse: collapse, layers: []};
        grouppedLayers.push(group);
        for (let title of groupDef.layers) {
            let layer = layersByTitle[title];
            group.layers.push(layer);
        }
    }

    return {
        layers: grouppedLayers,
        customLayersOrder: {
            top: orderByTitle['#custom-top'],
            bottom: orderByTitle['#custom-bottom'],

        }
    };
}
export {getLayers, layersDefs, groupsDefs, titlesByOrder};
